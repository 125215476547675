<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import newForm from "./baselifes/newForm";
import Swal from "sweetalert2";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'

// import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";
import { fetchSettings } from "./api";

export default {
    page: {
        title: "Base de vies de l'entreprise",
        meta: [{ name: "description", content: appConfig.description}],
    },
    import : { fetchSettings },
    components: { Layout, PageHeader, newForm ,
     Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      // DatasetSearch,
      DatasetShow,},
    data() {
    return {
      tableData: [],
      title: "LifeBases",
      baseslist: [],
      rows:'',
      active:false,
      selectedBase:[],
      selectedRows:[],
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Lifebase",
          active: true
        }
      ],
       cols: [
        {
          name: "Nom de la base",
          field: "name",
          sort: ''
        },
        {
          name: "Email",
          field: "email",
          sort: ''
        },
        {
          name: "Telephone",
          field: "phone",
          sort: ''
        },
        {
          name: "N° Chambres",
          field: "rooms",
          sort: ''
        },
        {
          name: "N° Bureaux",
          field: "offices",
          sort: ''
        },
        {
          name: "N° Restaurants",
          field: "restaurants",
          sort: ''
        },
        {
          name: "N° Cusines",
          field: "kitchens",
          sort: ''
        },
      ],
      selectedIndex:-1,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      filters:{
        name: {value: '', keys: ['name']},
        rooms: {value: '', keys: ['rooms']},
        offices: {value: '', keys: ['offices']},
        area: {value: '', keys: ['area']},
      }
    };
  },
  mounted(){
    this.getLifeBases();
  },
  watch:{
    selectedRows(){
        if(this.selectedRows.length){
         this.selectedBase = this.selectedRows;
        }else{
          this.selectedBase =[];
        }
      },
  },
 
  methods:{
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedBase = [row];
        this.selectedIndex = index;
      }
    },
    getLifeBases(){
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      this.$http.get('/base/lifebases/index')
      .then(response => {
          this.baseslist = response.data;
          this.selectedRows = []; this.selectedIndex = -1;
          loader.hide();
      })
      .catch(error => console.log(error))
    },
    refreshLifeBases(){
      this.getLifeBases();
    },
    deleteBase(name, uuid){
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la base  " + name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("base/lifebases/delete" ,{'uuid':uuid})
            .then((response) => {
              var data = response.data;
              if(data.errors){
                  this.$toast.error(response.data.original.msg);
                // Swal.fire("Erreur!", response.data.msg, "error")
              }else{
                  this.$toast.success(response.data.original.msg);
                // Swal.fire("Félicitations!", name, "success");
                 this.getLifeBases();
              }
              loader.hide();
              })
              .catch((error) => {
                loader.hide();
                Swal.fire("Erreur!", error, "error")
            })
            .finally(() => {});
        }
      });
    }
  }
    
}
</script>

<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des Bases de Vie</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <dataset v-slot="{ ds }" :ds-data="baseslist">
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>{{ row.name }}</td>
                                <td>{{ row.email }}</td>
                                <td>{{ row.phone }}</td>
                                <td>{{ row.rooms }}</td>
                                <td>{{ row.offices }}</td>
                                <td>{{ row.restaurants }}</td>
                                <td>{{ row.kitchens }}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <!-- <dataset-info class="mb-2 mb-md-0" /> -->
                    
                      <dataset-show :ds-show-entries="5" />
                    <dataset-pager />
                  </div>
                </dataset>

                <!-- <table class="table table-centered table-nowrap"> -->
                 <!--  <v-table
                    class="table table-hover"
                    :data="bases"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="selectedRows = $event "
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Nom de la base</th>
                      <th>Email</th>
                      <th>Telephone</th>
                      <th>N° Chambres</th>
                      <th>N° Bureaux</th>
                      <th>N° Restaurants</th>
                      <th>N° Cusines</th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                    <v-tr v-for="row in displayData" :key="row.id" :row="row">
                      <td>
                        {{ row.name }}
                      </td>
                      <td>{{ row.email }}</td>
                      <td>{{ row.phone }}</td>
                      <td>{{ row.rooms }}</td>
                      <td>{{ row.offices }}</td>
                      <td>{{ row.restaurants }}</td>
                      <td>{{ row.kitchens }}</td>
                    </v-tr>
                  </tbody>
                </v-table> -->
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="card mt-3">
            <newForm :base="selectedBase" @realoadLifeBaseList="refreshLifeBases" />
        </div>
      </div>
    </div>
    </Layout>
</template>